<template>
    <div id="index" ref="appRef">
      <!-- <div class="bg"> -->
        
        <el-carousel height="1080px" :autoplay="false">
          <!--  :interval="10000" arrow="always" -->
            <el-carousel-item v-for="(item,index) in image" :key="index">
                <!-- <h3 class="small">{{ item.url }}</h3> -->
                <img :src="item.url" alt="" class="img">
            </el-carousel-item>
        </el-carousel>

      <!-- </div> -->
    </div>
  </template>
  
<script>
import drawMixin from "../utils/drawMixin";
export default {
    mixins: [drawMixin],
    data() {
      return {
        image:[
            {url:require("../assets/hdp/1.jpg")},
            {url:require("../assets/hdp/2.jpg")},
            {url:require("../assets/hdp/3.jpg")},
            {url:require("../assets/hdp/4.jpg")},
            {url:require("../assets/hdp/5.jpg")},
            {url:require("../assets/hdp/6.jpg")},
            {url:require("../assets/hdp/7.jpg")},
            {url:require("../assets/hdp/8.jpg")},
            {url:require("../assets/hdp/9.jpg")},
            {url:require("../assets/hdp/10.jpg")},
            {url:require("../assets/hdp/11.jpg")},
            {url:require("../assets/hdp/12.jpg")},
            {url:require("../assets/hdp/13.jpg")},
        ]
      }
    },
    mounted() {

    },
    beforeDestroy() {
      
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';
.bg{
    position: relative;
    
}
.img{
  width: 1920px;
  height: 1080px;
    object-fit: cover;
}
</style>
  